import React from 'react';

import { supportPortalLink } from 'helper/constants/constants';
import strings from 'helper/localization/localization';

import {
  DashboardWelcome,
  DashboardBold,
  DashboardText,
} from './DashboardTextComponents';


const DashboardStartIntro = () => (
  <>
    <DashboardWelcome>{strings.startWelcome}</DashboardWelcome>
    <DashboardBold>{strings.startBodyBold1}</DashboardBold>
    <p>{strings.startBody1}</p>
    <DashboardBold>{strings.startBodyBold2}</DashboardBold>
    <p>{strings.startBody2}</p>
    <p>{strings.startBody3}</p>
    <DashboardBold>{strings.startBodyBold3}</DashboardBold>
    <DashboardText>
      {`${strings.startBody4} `}
      <a
        href={supportPortalLink}
        target="_blank"
        rel="noopener noreferrer"
      >
        {strings.integrationSupportPortal}
      </a>
    </DashboardText>
  </>
);

export default DashboardStartIntro;
