import React from 'react';
import { connect } from 'react-redux';
import { Link, useStaticQuery, graphql } from 'gatsby';
import { androidDocsLink } from 'helper/constants/constants';
import { userGradleDistibutionURL } from '../../../state/user';
import {
  TextCode,
  DashboardBold,
  DashboardText,
} from '../DashboardTextComponents';

const DashboardAndroidIntro = ({ gradleDistributionUrl }) => {
  const { allMarkdownRemark } = useStaticQuery(graphql`
    query markdownQuery {
      allMarkdownRemark(
        filter: {frontmatter: {title: {regex: "/androiddashboard/"}}}
        sort: {fields: frontmatter___title, order: ASC}
      ) {
        edges {
          node {
            html
          }
        }
      }
    }
  `);

  const GradleBuild = allMarkdownRemark.edges[0].node.html.replace('DISTRIBUTION_URL', gradleDistributionUrl);
  const GradleProperties = allMarkdownRemark.edges[1].node.html;
  const GradleVersion = allMarkdownRemark.edges[2].node.html;

  return (
    <>
      <DashboardText>
        {'Full documentation can be found '}
        <Link href={androidDocsLink} rel="noopener noreferrer" target="_blank">{'here'}</Link>
      </DashboardText>
      <DashboardBold>{'Update Gradle Version'}</DashboardBold>
      <DashboardText>
        {'Due to '}
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://github.com/gradle/gradle/security/advisories/GHSA-4cwg-f7qc-6r95"
        >
          {'this known security issue'}
        </a>
        {', in order to be able to download our MSDK artifacts, you will need to specify '}
        <b>{'5.6.X'}</b>
        {' as the minimum Gradle version in your '}
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://docs.gradle.org/current/userguide/gradle_wrapper.html"
        >{'gradle/wrapper/gradle-wrapper.properties'}</a>
      </DashboardText>
      <TextCode
        className="gradle-version-content"
        dangerouslySetInnerHTML={{ __html: GradleVersion }}
      />
      <DashboardBold>{'Update Properties File'}</DashboardBold>
      <DashboardText>
        {'In your gradle config directory, create or modify your '}
        <a
          href="https://docs.gradle.org/current/userguide/build_environment.html#sec:gradle_configuration_properties"
          target="_blank"
          rel="noopener noreferrer"
        >
          {'gradle.properties'}
        </a>
        {' file, adding your '}
        <b>{'Mimi Partner Portal '}</b>
        {'credentials:'}
      </DashboardText>
      <TextCode
        className="gradle-properties-content"
        dangerouslySetInnerHTML={{ __html: GradleProperties }}
      />
      <DashboardBold>{'Update Build File'}</DashboardBold>
      <DashboardText>
        {'In your project\'s '}
        <a
          href="https://developer.android.com/studio/build#build-files"
          target="_blank"
          rel="noopener noreferrer"
        >
          {'project/build.gradle'}
        </a>
        {' file, edit your repositories config with:'}
      </DashboardText>
      <TextCode
        className="gradle-build-content"
        dangerouslySetInnerHTML={{ __html: GradleBuild }}
      />
    </>
  );
};

export default connect(
  ({ user }) => ({ gradleDistributionUrl: userGradleDistibutionURL(user) }),
)(DashboardAndroidIntro);
