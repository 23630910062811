import styled, { css } from 'styled-components';

import {
  COLOR_FILE_BG,
  COLOR_GENERIC_TEXT,
  COLOR_INACTIVE_TEXT,
  JAVA_GREEN,
} from 'helper/constants/styles';
import { largeTablet, smallTablet } from 'helper/constants/mediaRules';

export const StyledFileListItem = styled.li`
  display: flex;
  align-items: center;
  padding: 1.5rem 0.75rem;
  margin-bottom: 0.7625rem;
  border-radius: 8px;
  background-color: ${COLOR_FILE_BG};
  font-weight: 700;
  box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
  transition: all 0.2s ease 0s;

  &:hover {
    transform: translate3d(-4px, -4px, 0);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  }

  ${largeTablet(css`
    padding: 2.65rem 1.5625rem;
  `)}
`;

export const StyledDownloadLink = styled.a`
  display: flex;
  color: ${COLOR_GENERIC_TEXT};
  text-decoration: none;
  flex-direction: column;
  cursor: pointer;

  > svg {
    margin-bottom: 0.5rem;
  }

  ${smallTablet(css`
    flex-direction: row;
    align-items: center;

    > svg {
      margin-bottom: 0;
    }
  `)}

  &:hover {
    color: ${JAVA_GREEN};

    path {
      fill: ${JAVA_GREEN};
    }
  }
`;
StyledDownloadLink.displayName = 'StyledDownloadLink(ProfileContents)';

export const StyledSecondaryInfo = styled.p`
  font-weight: 400;
  color: ${COLOR_INACTIVE_TEXT};
  margin: 0;
`;
