import React from 'react';
import { format, parse } from 'date-fns';

import { downloadFile } from 'api/api';
import { Col, Row } from 'components/Layout/Grid';
import FileIcon from 'images/file_icon';

import {
  StyledSecondaryInfo,
  StyledFileListItem,
  StyledDownloadLink,
} from './FileListComponents';

const PartnerVerticalFiles = (props) => {
  const {
    files,
    jwtToken,
    currentGroup,
  } = props;

  return (
    <>
      {files[currentGroup] && files[currentGroup].map(({
        key,
        date,
      }) => {
        const splitSlash = key.split('/');
        const splitPeriod = key.split('.');
        const formattedName = splitSlash.length >= 2 ? splitSlash.splice(2).join('') : key;
        const extension = splitPeriod[splitPeriod.length - 1];
        return (
          <StyledFileListItem key={key} id={`file-${key}`}>
            <Row alignItems="center">
              <Col
                width={[1, 1, 2 / 3]}
                as={StyledDownloadLink}
                id={`download-link-${key}`}
                rel="noopener noreferrer"
                role="button"
                tabindex="0"
                onClick={() => downloadFile(key, jwtToken)}
                target="_blank"
                mb={[3, 3, 0]}
              >
                <FileIcon type={extension} />
                <span>{formattedName}</span>
              </Col>
              <Col width={[1, 1, 1 / 3]} mb={[3, 3, 0]}>
                <StyledSecondaryInfo>{'Date Modified'}</StyledSecondaryInfo>
                {format(parse(date), 'YYYY/MM/DD')}
              </Col>
            </Row>
          </StyledFileListItem>
        );
      })}
    </>
  );
};

export default PartnerVerticalFiles;
