import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import PartnerVerticalFiles from 'components/ProfileContents/PartnerVerticalFiles';
import BinaryList from 'components/ProfileContents/BinaryList';
import DashboardAndroidIntro from 'components/ProfileContents/Android/DashboardAndroidIntro';
import DashboardStartIntro from 'components/ProfileContents/DashboardStartIntro';

import { iOSDocsLink } from 'helper/constants/constants';
import {
  COLOR_FORM_FIELD_BG,
  COLOR_FILE_BG,
  COLOR_GENERIC_TEXT,
  FONT_SIZE_DOCS_COPY,
  JAVA_GREEN,
} from 'helper/constants/styles';
import { largeTablet, smallTablet } from 'helper/constants/mediaRules';
import strings from 'helper/localization/localization.js';

import { StyledSecondaryInfo } from './FileListComponents';

import { DashboardText } from './DashboardTextComponents';

const StyledProfileWrapper = styled.div`
  width: 100%;
  padding: 1.5rem 0.75rem;
  background-color: ${COLOR_FORM_FIELD_BG};
  margin: 1.5rem 0;

  ${largeTablet(css`
    margin: 5.6875rem 0 3rem;
    padding: 2.375rem 3.875rem;
  `)}
`;
StyledProfileWrapper.displayName = 'StyledProfileWrapper(ProfileContents)';

const ProfileHeader = styled.div`
  margin-bottom: 2rem;
  h2 {
    font-weight: 700;
    margin-bottom: 0.5rem;
  }
`;
ProfileHeader.displayName = 'ProfileHeader(ProfileContents)';

const StyledButtonGroup = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  border-bottom: 1px solid ${COLOR_FILE_BG};
  margin-bottom: 1.6875rem;

  ${smallTablet(css`
    flex-direction: row;
  `)};
`;
StyledButtonGroup.displayName = 'StyledButtonGroup(ProfileContents)';

const StyledGroupButton = styled.button`
  padding: 0.6875rem 0.8125rem;
  background: none;
  border: none;
  border-bottom: ${({ active }) => (active ? `4px solid ${JAVA_GREEN}` : '4px solid transparent')};
  color: ${({ active }) => (active ? `${JAVA_GREEN}` : `${COLOR_GENERIC_TEXT}`)};
  font-size: ${FONT_SIZE_DOCS_COPY};
  font-family: "Galano", BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  cursor: pointer;
  appearance: none;
  text-align: left;

  ${smallTablet(css`
    text-align: center;
  `)}
`;

const StyledFileList = styled.ul`
  margin: 0;
  padding: 0 0.8125rem;
  word-break: break-word;
  list-style: none;
`;
StyledFileList.displayName = 'StyledFileList(ProfileContents)';

export default class ProfileContentsContainer extends PureComponent {
  state = {
    currentGroup: 'intro',
  }

  componentDidMount() {
    if (window.location.hash) {
      this.state.currentGroup = window.location.hash.split('#')[1];
    } else {
      window.location.hash = this.state.currentGroup;
    }
  }

  changeActiveGroup = (currentGroup) => {
    if (typeof window !== 'undefined') {
      window.location.hash = `#${currentGroup}`;
    }
    this.setState(() => ({ currentGroup }));
  }

  render() {
    const {
      isPreviousSession,
      userAttributes: {
        companyName,
        groups,
        email,
        jwtToken,
        firstName,
        lastName,
        userLanguage,
        sdkFlavor,
      },
      fileGroupOrder,
      files,
      androidBinaries,
      iosBinaries,
    } = this.props;

    // groups is always an array, but during build it's undefined
    const userAuthorized = (groups || []).includes('auth_4');

    const EmptyState = ({ groupFiles }) => !groupFiles.length && files.loaded && (
      <StyledSecondaryInfo>
        {`${strings.noFiles}`}
      </StyledSecondaryInfo>
    );

    // With Chinese naming it is custom to put Surname first and then first names after
    const fullNameLocalized = userLanguage === 'zh-CN' ? `${lastName} ${firstName}` : `${firstName} ${lastName}`;
    const welcomeString = `${isPreviousSession ? strings.welcomeBack : strings.heyThere}, ${fullNameLocalized}`;

    return (
      <StyledProfileWrapper id="qa-dashboard-page">
        <ProfileHeader id="user-info">
          <h2>
            {welcomeString}
          </h2>
          {companyName && <StyledSecondaryInfo id="company-name" style={{ textTransform: 'capitalize' }}>{companyName}</StyledSecondaryInfo>}
          <StyledSecondaryInfo id="user-email">{email}</StyledSecondaryInfo>
        </ProfileHeader>
        <StyledButtonGroup>
          {
            fileGroupOrder.map(({ group, text }) => (
              <StyledGroupButton
                id={`file-group-${group}`}
                type="button"
                active={group === this.state.currentGroup}
                onClick={() => this.changeActiveGroup(group)}
                key={group}
              >
                {strings[text]}
                {/* eslint-disable-next-line comma-dangle */}
              </StyledGroupButton>
            ))
          }
        </StyledButtonGroup>
        {this.state.currentGroup === 'intro' && <DashboardStartIntro />}
        {
          ['basics', 'integration'].includes(this.state.currentGroup) && (
            <>
              <StyledFileList>
                <PartnerVerticalFiles
                  files={files.files}
                  jwtToken={jwtToken}
                  currentGroup={this.state.currentGroup}
                />
              </StyledFileList>
              <EmptyState groupFiles={files.files[this.state.currentGroup]} />
            </>
          )
        }
        {
          this.state.currentGroup === 'android-assets' && userAuthorized && (
            <>
              <StyledFileList>
                {/* Display the Android Gradle Instructions */}
                <DashboardAndroidIntro />
                <br />
                {/* Display the Android Binaries List */}
                <BinaryList
                  jwtToken={jwtToken}
                  binariesList={androidBinaries}
                  platform={'android'}
                  sdkFlavor={sdkFlavor}
                />
                <EmptyState groupFiles={androidBinaries} />
              </StyledFileList>
            </>
          )
        }
        {
          this.state.currentGroup === 'ios-assets' && userAuthorized && (
            <>
              <StyledFileList>
                <DashboardText>
                  {'iOS Documentation can be accessed '}
                  <a href={iOSDocsLink} rel="noopener noreferrer" target="_blank">
                    {'here'}
                  </a>
                </DashboardText>
                <BinaryList
                  binariesList={iosBinaries}
                  jwtToken={jwtToken}
                  platform={'ios'}
                  sdkFlavor={sdkFlavor}
                />
                <EmptyState groupFiles={iosBinaries} />
              </StyledFileList>
            </>
          )
        }
      </StyledProfileWrapper>
    );
  }
}

ProfileContentsContainer.propTypes = {
  userAttributes: PropTypes.shape({
    jwtToken: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    companyName: PropTypes.string.isRequired,
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    groups: PropTypes.array.isRequired,
    userLanguage: PropTypes.string.isRequired,
  }),
  fileGroupOrder: PropTypes.arrayOf(PropTypes.object).isRequired,
  files: PropTypes.shape({
    intro: PropTypes.array,
    basics: PropTypes.array,
    integration: PropTypes.array,
  }).isRequired,
  isPreviousSession: PropTypes.bool.isRequired,
  androidBinaries: PropTypes.arrayOf(PropTypes.object).isRequired,
  iosBinaries: PropTypes.arrayOf(PropTypes.object).isRequired,
};

ProfileContentsContainer.defaultProps = {
  username: 'Hey there!',
  companyName: null,
};
