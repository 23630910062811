import styled, { css } from 'styled-components';

import { tinyPhone } from 'helper/constants/mediaRules';

import {
  FONT_SIZE_DOCS_COPY,
  JAVA_GREEN,
} from 'helper/constants/styles';

export const DashboardWelcome = styled.h3`
  color: ${JAVA_GREEN};
  font-weight: 700;
`;

export const DashboardText = styled.p`
  line-height: 1.6em;

  > a {
    color: ${JAVA_GREEN};
    cursor: pointer;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
`;

export const DashboardBold = styled.p`
  font-weight: 700;
  font-size: ${FONT_SIZE_DOCS_COPY};
  margin-top: 2rem;
`;

export const TextCode = styled.div`
  box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);

  ${tinyPhone(css`
    font-size: 0.8em;
  `)}
`;
