import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { connect } from 'react-redux';

import { COLOR_FORM_FIELD_BG } from 'helper/constants/styles';
import ProfileContentsContainer from 'components/ProfileContents/ProfileContentsContainer';

import { fetchFiles } from 'state/files';
import { listBinaries } from 'api/api';

import SEO from '../../components/Seo';

const StyledProfileWrapper = styled.div`
  width: 100%;
  padding: 2.375rem 3.875rem;
  margin-top: 5.6875rem;
  background-color: ${COLOR_FORM_FIELD_BG};
`;
StyledProfileWrapper.displayName = 'StyledProfileWrapper(Profile)';

const fileGroupOrder = [
  { group: 'intro', text: 'tabIntro' },
  { group: 'basics', text: 'tabBasics' },
  { group: 'integration', text: 'tabIntegration' },
  { group: 'ios-assets', text: 'tabIos' },
  { group: 'android-assets', text: 'tabAndroid' },
];

class Profile extends Component {
  state = {
    androidBinaries: [],
    iosBinaries: [],
  }

  componentDidMount() {
    this.props.dispatch(fetchFiles(this.props.user.attributes.jwtToken));
    this.getAndroidFiles();
    this.getIosFiles();
  }

  getAndroidFiles = async () => {
    try {
      const androidBinaries =
        await listBinaries(this.props.user.attributes.jwtToken, this.props.user.attributes.sdkFlavor, 'android');

      this.setState({ androidBinaries: typeof androidBinaries !== 'object' ? [] : androidBinaries });
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e);
    }
  }

  getIosFiles = async () => {
    try {
      const iosBinaries =
        await listBinaries(this.props.user.attributes.jwtToken, this.props.user.attributes.sdkFlavor, 'ios');

      this.setState({ iosBinaries: typeof iosBinaries !== 'object' ? [] : iosBinaries });
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e);
    }
  }


  render() {
    const { user: { isPreviousSession, attributes }, files } = this.props;

    return (
      <>
        <SEO title="Dashboard" />
        <ProfileContentsContainer
          files={files}
          fileGroupOrder={fileGroupOrder}
          userAttributes={attributes}
          androidBinaries={this.state.androidBinaries}
          iosBinaries={this.state.iosBinaries}
          isPreviousSession={isPreviousSession}
        />
      </>
    );
  }
}

Profile.propTypes = {
  user: PropTypes.shape({
    isPreviousSession: PropTypes.bool.isRequired,
    attributes: PropTypes.shape({
      jwtToken: PropTypes.string.isRequired,
      email: PropTypes.string.isRequired,
      companyName: PropTypes.string.isRequired,
      firstName: PropTypes.string.isRequired,
      lastName: PropTypes.string.isRequired,
      groups: PropTypes.array.isRequired,
    }),
  }).isRequired,
  files: PropTypes.shape({
    files: PropTypes.shape({
      start: PropTypes.array,
      basics: PropTypes.array,
      integration: PropTypes.array,
    }).isRequired,
  }),
};

function mapStateToProps({ files, user }) {
  return {
    files,
    user,
  };
}
export default connect(mapStateToProps)(Profile);
