import React from 'react';
import { format, parse } from 'date-fns';

import { downloadFile } from 'api/api';
import { Col, Row } from 'components/Layout/Grid';
import FileIcon from 'images/file_icon';

import { DashboardBold } from './DashboardTextComponents';

import {
  StyledSecondaryInfo,
  StyledFileListItem,
  StyledDownloadLink,
} from './FileListComponents';

const BinaryList = (props) => {
  const { jwtToken, binariesList, platform, sdkFlavor } = props;

  return (
    <>
      <DashboardBold>{'Download'}</DashboardBold>
      {binariesList.map(({ key, version, fileName, date }) => (
        <StyledFileListItem key={key}>
          <Row>
            <Col
              width={[1, 1, 0.4]}
              as={StyledDownloadLink}
              rel="noopener noreferrer"
              role="button"
              tabindex="0"
              onClick={() => downloadFile(`${sdkFlavor}/${key}`, jwtToken)}
              id={`${platform}-binary-link-${fileName}`}
              target="_blank"
              mb={[3, 3, 0]}
            >
              <FileIcon type={platform} />
              {fileName}
            </Col>
            <Col width={[1, 1, 1 / 4]} mb={[3, 3, 0]}>
              <StyledSecondaryInfo>{'Date Modified'}</StyledSecondaryInfo>
              {format(parse(date), 'YYYY/MM/DD')}
            </Col>
            <Col width={[1, 1, 1 / 4]}>
              <StyledSecondaryInfo>{'Version'}</StyledSecondaryInfo>
              {version || '-'}
            </Col>
          </Row>
        </StyledFileListItem>
      ))}
    </>
  );
};

export default BinaryList;
